import { classAdd, classRemove, classToggle } from '../commons'

const password = document.querySelectorAll('.form-icon-wrapper')

if (password != null) {
  // const input = document.querySelectorAll('.form-group')
  Array.prototype.slice.call(password, 0).forEach(function (el) {
    el.addEventListener('click', function (e) {
      classToggle(e.target, '-maskOut')

      const icon = e.currentTarget.querySelector('svg')

      if (e.target.className.includes('-maskOut')) {
        e.target.previousElementSibling.setAttribute('type', 'text')
        classAdd(icon, 'fa-eye')
        classRemove(icon, 'fa-eye-slash')
      } else {
        e.target.previousElementSibling.setAttribute('type', 'password')
        classAdd(icon, 'fa-eye-slash')
        classRemove(icon, 'fa-eye')
      }
    })
  })
}
