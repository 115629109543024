const classAdd = function (item, css) {
  item.classList.add(css)
}
const classRemove = function (item, css) {
  item.classList.remove(css)
}

const classToggle = function (item, css) {
  item.classList.toggle(css)
}

const addOpen = function (item, event, func) {
  item.addEventListener(event, () => {
    classAdd(item, '-open')
  })
}
const removeOpen = function (item, event) {
  item.addEventListener(event, () => {
    classRemove(item, '-open')
  })
}

const drawerToggle = function (item, event) {
  item.addEventListener('touchend', (e) => {
    classToggle(e.target.parentNode, '-open')
    if (e.target.parentNode.className.includes('-open')) {
      classAdd(e.target.parentNode.nextElementSibling, '-open')
    } else {
      classRemove(e.target.parentNode.nextElementSibling, '-open')
    }
  })
}

export { classAdd, classRemove, classToggle, addOpen, removeOpen, drawerToggle }
