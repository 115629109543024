window.onload = function () {
  const url = location.href
  if (document.querySelector('.article-shares') != null) {
    document
      .getElementById('pdf')
      .setAttribute('href', `https://www.facebook.com/dialog/share?href=${url}`)
    document
      .getElementById('fb')
      .setAttribute('href', `https://www.facebook.com/dialog/share?href=${url}`)
    document
      .getElementById('tw')
      .setAttribute('href', `https://twitter.com/intent/tweet?url=${url}`)
    document
      .getElementById('line')
      .setAttribute(
        'href',
        `https://social-plugins.line.me/lineit/share?url=${url}`
      )
  }
}
