import stickybits from 'stickybits';

stickybits('.header', {
  useStickyClasses: true
});


const userAgent = window.navigator.userAgent.toLowerCase()
if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
  const header = document.querySelector('.header')
  header.classList.add('stickyfill')
  Stickyfill.add(document.querySelectorAll('.stickyfill'));
}

