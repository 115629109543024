import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

let anchors = document.getElementsByTagName("a");
Array.prototype.forEach.call(anchors, function (anchor) {
  anchor.addEventListener("click", function (e) {
    if (/#/.test(this.getAttribute("href"))) {
      e.preventDefault();

      let href = this.getAttribute("href").replace("#", "");
      var target = document.getElementById("top");

      if (document.getElementById(href)) {
        target = document.getElementById(href);
      } else if (document.getElementById("continued-part")) {
        // 記事詳細において、目次の遷移先がない場合はログイン・会員登録パートを表示
        target = document.getElementById("continued-part");
      }

      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  });
});
