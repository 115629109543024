// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@fortawesome/fontawesome-pro/js/all'
import 'core-js/stable'
import 'lazysizes'
import 'regenerator-runtime/runtime'
import './componets/checkboxToggle'
import './componets/copyUrl'
import './componets/drawer'
import './componets/dropdown'
import './componets/password'
import './componets/radioSubmit'
import './componets/scroll'
import './componets/shares'
import './componets/sticky'
import './componets/toggle'

// .tabs内の.tabs.-disbled.-onlinkを取得
const tabs = document.querySelectorAll('.tabs-links')
// クリックした要素を取得
if (tabs)
tabs.forEach((tab) => {
  tab.addEventListener('click', (e) => {
    // e.targetのaria-labelを取得
    const ariaLabel = e.target.getAttribute('aria-label')
    if (!ariaLabel) {
      return true
    }

    e.preventDefault()

    //　クリックした要素の親.tab内にある.-onLinkの要素を取得し、.-activeクラスを付与 .-disbledクラスを削除
    // tab.parentNode.querySelector('.tabs-link.-onLink.-active').classList.toggle('-disabled')
    tab.parentNode.querySelector('.tabs-link.-onLink.-active').classList.remove('-first')
    tab.parentNode.querySelector('.tabs-link.-onLink.-active').classList.remove('-active')
    e.target.classList.add('-active')
    // e.target.classList.remove('-disabled')

    // e.currentTarget内の.tabs-listsを取得しaria-labelがe.target.getAttribute('aria-label')と一致するものを取得
    const tabLists = e.currentTarget.parentNode.querySelectorAll('.tabs-lists')
    tabLists.forEach((tabList) => {
      if (tabList.getAttribute('aria-label') === ariaLabel) {
        tabList.classList.add('-active')
        tabList.classList.remove('-disabled')
      } else {
        tabList.classList.add('-disabled')
        tabList.classList.remove('-active')
      }
    })
  })
  }
)

const modal = document.getElementById("myModal");
// button css class -isModalを全て取得
const btns = document.getElementsByClassName("-isModal");
const span = document.getElementsByClassName("close")[0];

// btns内のbuttonがクリックされたら
if (btns) {
  Array.prototype.slice.call(btns, 0).forEach(function (el) {
    el.addEventListener('click', function (e) {
      // modalを表示
      modal.style.display = "flex";
    })
  })
}
if (span) span.onclick = function () {
  modal.style.display = "none";
}

window.onclick = function (event) {
  if (event.target == modal) {
    modal.style.display = "none";
  }
}


require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
