window.onload = function () {
  const toggle = document.querySelectorAll('.toggle')
  const toggleTitle = document.querySelectorAll('.toggle-click-area')
  if (toggle.length > 0) {
    Array.prototype.slice.call(toggleTitle, 0).forEach(function (el) {
      el.addEventListener('click', function (e) {
        console.info(el)
        e.target.parentNode.nextElementSibling.classList.toggle('-open')
        e.target.parentNode.classList.toggle('-open')
      })
    })
  }
}
