import { addOpen, classAdd, classRemove, removeOpen } from '../commons'

const gNDropDown = document.querySelectorAll('.global-nav-item.is-dropdown')
const gNsDropDown = document.querySelectorAll(
  '.global-nav-dropdownLink.is-sDropdown'
)
const gNsDropDownItem = document.querySelectorAll('.global-nav-sDropdownItem')
const gNsDropdownLists = Array.from(
  document.querySelectorAll('.global-nav-dropdownLink.is-sDropdown')
)

const hDdropDown = document.querySelectorAll('.header-btn-area.is-dropdown')
const hDdropDownInner = document.querySelector(
  '.header-btn-area.is-dropdown > .header-dropdownInner'
)

Array.prototype.slice.call(gNDropDown, 0).forEach(function (el) {
  addOpen(el, 'mouseenter')
  removeOpen(el, 'mouseleave')
})

Array.prototype.slice.call(gNsDropDown, 0).forEach(function (el) {
  el.addEventListener('mouseenter', () => {
    classAdd(el, '-open')
    const index = gNsDropdownLists.findIndex((list) =>
      Array.from(list.classList).includes('-open')
    )
    classAdd(gNsDropDownItem[index], '-open')
  })
  el.addEventListener('mouseleave', () => {
    classRemove(document.querySelector('.is-sDropdown.-open'), '-open')
    classRemove(
      document.querySelector('.global-nav-sDropdownItem.-open'),
      '-open'
    )
  })
})

Array.prototype.slice.call(hDdropDown, 0).forEach(function (el) {
  addOpen(el, 'mouseenter')
  removeOpen(hDdropDownInner, 'mouseleave')
})
