import { classRemove, classToggle, drawerToggle } from "../commons";

const userAgent = window.navigator.userAgent.toLowerCase();
const isIE =
  userAgent.indexOf("msie") !== -1 || userAgent.indexOf("trident") !== -1;
const drawerClick = document.querySelectorAll(".is-touchend");
const drawer = document.querySelector(".drawer");
const drawerBg = document.querySelector(".wrapper");
const drawerClose = document.querySelector(".drawer-nav-close");
const drawerNavAll = document.querySelectorAll(".drawer-nav-flag");
const drawerNavItem = document.querySelectorAll(".drawer-nav-item");
const drawerNavItemIn = document.querySelectorAll(
  ".drawer-nav-toggleItem.-inToggle"
);
if (!isIE) {
  if (drawer) {
    drawerClick.forEach(function (el) {
      el.addEventListener(
        "touchstart",
        function (e) {
          classToggle(e.target, "-open");
          classToggle(drawerBg, "-drawer");
          document.querySelector("body").style.overflow = "hidden";
          if (e.target.classList[0] === "header-sp-hamburger") {
            drawerNavAll[0].style.display = "block";
            if (drawerNavAll[1]) {
              drawerNavAll[1].style.display = "none";
            }
          } else {
            drawerNavAll[0].style.display = "none";
            if (drawerNavAll[1]) {
              drawerNavAll[1].style.display = "block";
            }
          }
          if (e.target.classList[1] === "header-sp-userIcon") {
            drawerNavAll[0].style.display = "none";
            if (drawerNavAll[1]) {
              drawerNavAll[1].style.display = "block";
            }
          }
        },
        { passive: true }
      );
    });

    drawerClose.addEventListener(
      "touchstart",
      (e) => {
        classRemove(drawerBg, "-drawer");
        document.querySelector("body").style.overflow = "visible";
      },
      { passive: true }
    );
    drawerNavItem.forEach(function (el) {
      drawerToggle(el, "touchstart");
    });
    drawerNavItemIn.forEach(function (el) {
      drawerToggle(el, "touchstart");
    });
  }
}
