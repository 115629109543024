const form = document.querySelector("#radioSubmitForm");
if (form) {
  const nodes = document.querySelectorAll("input[type=radio]");
  console.log(nodes);
  nodes.forEach((n) => {
    n.addEventListener("change", () => {
      form.submit();
    });
  });
}
