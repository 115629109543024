import { classToggle } from '../commons'

const isBindCheck = document.querySelector('#bindCheck')
if (isBindCheck) {

  const ua = navigator.userAgent;
  if(ua.indexOf('Trident') !== -1) { // IE11
    setTimeout(function(){
      document.querySelector('.form-checkbox-input-ie').focus();
      document.getElementById('bindCheck').checked = false;
    }, 10)
  } else {
    setTimeout(function(){
      isBindCheck.focus();
      document.getElementById('bindCheck').checked = false;
    }, 10);
  }

  const isBindForm = document.getElementById("is-bind-form")

  const disableSubmit = (evt) => {
    evt.preventDefault()
  }
  isBindForm.addEventListener('submit', disableSubmit, false);

  isBindCheck.addEventListener('click', function (e) {
    let isBindFlag = document.getElementById('bindCheck').checked

    const isBindElements = document.querySelectorAll('#is-bind-elements')
    Array.prototype.slice.call(isBindElements, 0).forEach(function (el) {
      classToggle(el, '-bind-check')
    })

    if (isBindFlag) {
      isBindForm.removeEventListener('submit', disableSubmit)
    } else {
      isBindForm.addEventListener('submit', disableSubmit, false);
    }
  })
}
