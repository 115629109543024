import { classAdd, classRemove, classToggle } from "../commons";

const copyLink = document.querySelectorAll(".training-article-option-link");

if (copyLink) {
  copyLink.forEach((link) => {
    link.addEventListener("click", function (e) {
      const balloon = link.previousElementSibling;
      if (!balloon) {
        return;
      }

      balloon.style.opacity = 1;
      balloon.style.visibility = "visible";

      if (window.clipboardData) {
        window.clipboardData.setData("Text", location.href);
      } else if (navigator.clipboard) {
        navigator.clipboard.writeText(location.href);
      }

      setTimeout(() => {
        balloon.style.opacity = 0;
      }, 1000);
      setTimeout(() => {
        balloon.style.visibility = "hidden";
      }, 1300);
    });
  });
}
